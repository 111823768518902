import {useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import UserController from 'puddleglum/api/UserController';

const usePermission = (permissionName: string) => {
	const [hasPermission, setHasPermission] = useState<'read' | 'all' | 'none'>();

	const {data: me} = useQuery(['me'], async () => {
		const response = await UserController.me();
		return response.data;
	});

	useEffect(() => {
		let hasRead = false;
		let hasEdit = false;

		if (me) {
			// eslint-disable-next-line no-restricted-syntax
			for (const role of me.roles ?? []) {
				// eslint-disable-next-line no-restricted-syntax
				for (const permission of role?.permissions ?? []) {
					if (permissionName === permission.name) {
						if (permission.pivot.can_read === 1) {
							hasRead = true;
						}
						if (permission.pivot.can_edit === 1) {
							hasRead = true;
							hasEdit = true;
						}
					}
				}
			}
		}

		if (hasRead && !hasEdit) {
			setHasPermission('read');
		} else if (!hasRead && !hasEdit) {
			setHasPermission('none');
		} else if (hasEdit && hasRead) {
			setHasPermission('all');
		}
	}, [me]);

	return hasPermission;
};

export default usePermission;
